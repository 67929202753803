import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../../problems/problem.service';
import { TaskProtectedValueCreateInterface } from '../definitions/task-protected-value-create-interface';
import { TaskProtectedValueCreateRequestInterface } from '../definitions/task-protected-value-create-request-interface';

@Injectable()
export class TaskProtectedValueCreateService {

  private apiUrl: string = environment.rest.apiV2Url;

  public constructor(private client: HttpClient) {}

  public create(taskId: string, data: TaskProtectedValueCreateRequestInterface): Observable<TaskProtectedValueCreateInterface> {
    const url = `${this.apiUrl}/tasks/${taskId}/protected-values`;

    return this.client.post<TaskProtectedValueCreateInterface>(url, data).pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}