import { Injectable } from '@angular/core';
import { TaskPreviewService } from '../graphql/tasks/task-preview.service';
import { TaskServiceInterface } from '../graphql/tasks/definitions/task-service-interface';
import { Observable, Observer } from 'rxjs';
import { TaskCreateService } from '../graphql/tasks/create.service';
import { UploadTaskFileService } from '../graphql/taks-file/upload-task-file.service';
import { CreateTaskInterface } from '../graphql/tasks/definitions/task-create-interface';
import { AssumeTaskService } from '../graphql/tasks/assume-task.service';
import { TaskByIdService } from '../graphql/tasks/by-id.service';
import { Subscription } from 'rxjs';
import { UpdateTaskInterface } from '../graphql/tasks/definitions/task-update-interface';
import { TaskUpdateService } from '../graphql/tasks/update.service';
import { SetFileGroupService } from '../graphql/taks-file/set-file-group.service';
import { TaskFileInterface } from '../graphql/taks-file/definitions/task-file-interface';
import { constants } from '../../utils/constants';
import { TaskToCalculationService } from '../graphql/tasks/to-calculation.service';
import { readInputFile } from '../../utils/file-reader';
import { TaskToRevisionService } from '../graphql/tasks/to-revision.service';
import { AssumeRevisionService } from '../graphql/tasks/assume-revision.service';
import { TaskFileGroupInterface } from '../graphql/task-file-group/definitions/task-file-group-interface';
import { CompleteTaskService } from '../graphql/tasks/complete.service';
import { TaskListVariablesInterface } from '../graphql/tasks/definitions/task-list-variables-interface';
import { AssignResolverService } from '../graphql/tasks/assign-resolver.service';
import { AssignReviserService } from '../graphql/tasks/assign-reviser.service';
import { ApproveAllFileGroupsService } from '../graphql/tasks/approve-all-file-groups.service';
import { RemoveTaskFileService } from '../graphql/taks-file/remove-task-file.service';
import { MarkAsRevisedService } from '../graphql/tasks/mark-as-revised.service';
import { RejectFromRevisionService } from '../graphql/tasks/reject-from-revision';
import { RevisionMessageInterface } from '../graphql/task-revision/definitions/revision-message-interface';
import { RemoveInternalTaskFileService } from '../graphql/taks-internal-file/remove-internal-task-file.service';
import { UploadInternalTaskFileService } from '../graphql/taks-internal-file/upload-internal-task-file.service';
import { TaskRemoveService } from '../graphql/tasks/remove.service';
import { TaskMarkAsNotExecutedService } from '../graphql/tasks/mark-as-not-executed.service';
import { LoggerService } from '../logger.service';
import { NavigationStart, Router } from '@angular/router';
import { DocumentNode } from 'graphql';
import { OnlineTaskInspectionDoneService } from '../graphql/tasks/online-task-inspection-done.service';
import { CreateOnlineTaskInspectionService } from '../graphql/tasks/create-online-task-inspection.service';
import { CreateOnlineInspectionInterface } from '../graphql/tasks/definitions/create-online-inspection-interface';
import { catchError, map } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { SetDefaultTaskFileService } from '../graphql/taks-file/set-default-task-file.service';
import {
  DocumentationCompletedResponse,
  TaskDocumentationCompletedService
} from '../graphql/tasks/task-documentation-completed.service';
import { TaskListResultInterface } from '../graphql/tasks/definitions/task-list-result-interface';
import { TaskGetGtNetLinkService } from '../rest/tasks/task-get-gt-net-link.service';
import { TaskGtNetLinkResponseInterface } from '../rest/tasks/definitions/task-gt-net-link-response-interface';
import { TaskQuarterYearEmailSent } from '../rest/tasks/task-quarter-year-email-sent.service';

@Injectable()
export class TasksService {
  updateSubscription: Subscription | null = null;
  tasksUnknownError = false;
  taskHasBeenAssumed = false;
  successfullyAssumed = false;
  taskListVariables: TaskListVariablesInterface = {
    searchText: null,
    states: [
      constants.TASK_STATE_NEW,
      constants.TASK_STATE_INFORMATION_GATHERING,
      constants.TASK_STATE_DOCUMENTS_MISSING,
      constants.TASK_STATE_CALCULATION_REQUIRED,
      constants.TASK_STATE_CALCULATED,
      constants.TASK_STATE_REVISION,
      constants.TASK_STATE_REVISED,
      constants.TASK_STATE_COMPLETED,
      constants.TASK_STATE_INVOICED,
    ],
    partners: null,
    assignedTo: null,
    onlyMy: true,
    taskTags: null,
    offset: 0,
    limit: 25,
    orderDesc: true,
    orderBy: ['createdAt'],
    orderByConst: constants.ORDER_BY_TASK_CREATED_AT,
  };

  constructor(
    private createOnlineTaskInspectionService: CreateOnlineTaskInspectionService,
    private taskByIdService: TaskByIdService,
    private taskPreviewService: TaskPreviewService,
    private taskCreateService: TaskCreateService,
    private taskUpdateService: TaskUpdateService,
    private assumeTaskService: AssumeTaskService,
    private assignResolverService: AssignResolverService,
    private assignReviserService: AssignReviserService,
    private taskToCalculationService: TaskToCalculationService,
    private taskToRevisionService: TaskToRevisionService,
    private taskRemoveService: TaskRemoveService,
    private taskMarkAsNotExecutedService: TaskMarkAsNotExecutedService,
    private taskDocumentationCompletedService: TaskDocumentationCompletedService,
    private assumeRevisionService: AssumeRevisionService,
    private markAsRevisedService: MarkAsRevisedService,
    private rejectFromRevisionService: RejectFromRevisionService,
    private completeTaskService: CompleteTaskService,
    private taskFileUploadService: UploadTaskFileService,
    private setFileGroupService: SetFileGroupService,
    private setDefaultTaskFileService: SetDefaultTaskFileService,
    private removeTaskFileService: RemoveTaskFileService,
    private taskInternalFileUploadService: UploadInternalTaskFileService,
    private removeInternalTaskFileService: RemoveInternalTaskFileService,
    private approveAllFileGroupsService: ApproveAllFileGroupsService,
    private onlineTaskInspectionDoneService: OnlineTaskInspectionDoneService,
    private taskGetGtNetLinkService: TaskGetGtNetLinkService,
    private taskQuarterYearEmailSent: TaskQuarterYearEmailSent,
    private logger: LoggerService,
    router: Router
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.resetState();
      }
    });
  }

  resetState() {
    this.tasksUnknownError = false;
    this.taskHasBeenAssumed = false;
    this.successfullyAssumed = false;
  }

  getTaskById(taskId: string, query?: DocumentNode): Observable<any> {
    return this.taskByIdService
      .getTaskById(taskId, query as any)
      .valueChanges.pipe(
        map((taskData) => {
          return this._mapTask(taskData.data.task.byId.output);
        })
      );
  }

  getTaskByIdTogglePolling(isPolling: boolean) {
    return this.taskByIdService.togglePolling(isPolling);
  }

  createOnlineInspection(
    partnerId: string,
    claimNumber: string,
    carLicensePlate: string,
    mutation?: DocumentNode
  ): Promise<any> {
    return this.createOnlineTaskInspectionService
      .createOnlineInspection(
        partnerId,
        claimNumber,
        carLicensePlate,
        mutation as any
      )
      .pipe(
        map((taskData) => {
          return this._mapCreateOnlineInspection(
            taskData.data?.task.createOnlineInspection.output
          );
        })
      )
      .toPromise();
  }

  public getTaskPreviews(query?: DocumentNode): Observable<TaskListResultInterface> {
    return this.taskPreviewService
      .getList(this.taskListVariables, query as any)
      .pipe(
        map((taskData) => {
          return {
            totalCount: taskData.data.task.list.output.totalCount,
            rows: taskData.data.task.list.output.rows.map((td) =>
              this._mapPreview(td)
            )
          };
        }),
        catchError((err, caught) => {
          return new Observable((observer: Observer<any>) => {
            observer.next({
              output: null,
              errors: err.graphQLErrors,
            });
            observer.complete();
          });
        })
      );
  }

  createTask(
    createTask: CreateTaskInterface,
    mutation: DocumentNode
  ): Promise<any> {
    createTask.clientIdentificationCode =
      createTask.clientIdentificationCode === ''
        ? null
        : createTask.clientIdentificationCode;
    return this.taskCreateService.create(createTask, mutation).toPromise();
  }

  updateTask(
    taskId: string,
    updatePatch: UpdateTaskInterface,
    mutation: DocumentNode
  ) {
    return this.updateTask$(taskId, updatePatch, mutation).toPromise();
  }

  updateTask$(
    taskId: string,
    updatePatch: UpdateTaskInterface,
    mutation: DocumentNode
  ) {
    return this.taskUpdateService.update(taskId, updatePatch, mutation);
  }

  removeTask(taskId: string, mutation: DocumentNode) {
    return this.taskRemoveService.remove(taskId, mutation).toPromise();
  }

  markAsNotExecutedTask(
    taskId: string,
    reason: string,
    mutation: DocumentNode
  ) {
    return this.taskMarkAsNotExecutedService
      .markAsNotExecuted(taskId, reason, mutation)
      .toPromise();
  }

  public documentationCompleted(
    taskId: string,
    mutation: DocumentNode
  ): Promise<FetchResult<DocumentationCompletedResponse>> {
    return this.taskDocumentationCompletedService
      .documentationCompleted(taskId, mutation)
      .toPromise();
  }

  assumeTask(taskId: string, mutation: DocumentNode): Promise<any> {
    return this.assumeTaskService
      .assume(taskId, mutation)
      .pipe(
        map((taskData) => {
          if (taskData.data.task.assume.output) {
            this.successfullyAssumed = true;
            return {
              output: this._mapTaskFile(taskData.data.task.assume.output),
              errors: null,
            };
          } else {
            this.tasksUnknownError = true;
          }
          return taskData.data.task.assume;
        }),
        catchError((errors) => {
          this.logger.error('Assuming task failed', errors);
          if (
            errors.graphQLErrors.find(
              (error) => error.message === constants.ERROR_ALREADY_ASSIGNED_FULL
            )
          ) {
            this.taskHasBeenAssumed = true;
          } else {
            this.tasksUnknownError = true;
          }
          return Observable.create((observer) => {
            observer.next({
              output: null,
              errors: errors.graphQLErrors,
            });
            observer.complete();
          });
        })
      )
      .toPromise();
  }

  assignResolver(
    taskId: string,
    mutation: DocumentNode,
    newResolverId?: string
  ): Promise<any> {
    return this.assignResolverService
      .assignResolver(taskId, newResolverId, mutation)
      .toPromise();
  }

  assignReviser(
    taskId: string,
    mutation: DocumentNode,
    newReviserId?: string
  ): Promise<any> {
    return this.assignReviserService
      .assignReviser(taskId, newReviserId, mutation)
      .toPromise();
  }

  toCalculation(taskId: string, mutation: DocumentNode): Promise<any> {
    return this.taskToCalculationService
      .toCalculation(taskId, mutation)
      .toPromise();
  }

  sendToRevision(taskId: string, mutation: DocumentNode): Promise<any> {
    return this.taskToRevisionService.toRevision(taskId, mutation).toPromise();
  }

  assumeRevision(taskId: string, mutation: DocumentNode): Promise<any> {
    return this.assumeRevisionService
      .assumeRevision(taskId, mutation)
      .toPromise();
  }

  markAsRevised(taskId: string, mutation: DocumentNode): Promise<any> {
    return this.markAsRevisedService
      .markAsRevised(taskId, mutation)
      .toPromise();
  }

  rejectFromRevision(
    taskId: string,
    reason: string,
    mutation: DocumentNode
  ): Promise<any> {
    return this.rejectFromRevisionService
      .rejectFromRevision(taskId, reason, mutation)
      .toPromise();
  }

  completeTask(taskId: string, mutation: DocumentNode): Promise<any> {
    return this.completeTaskService.complete(taskId, mutation).toPromise();
  }

  approveAllTaskFileGroups(
    taskId: string,
    type: string,
    mutation: DocumentNode
  ): Promise<any> {
    return this.approveAllFileGroupsService
      .approveByType(taskId, type, mutation)
      .toPromise();
  }

  destroy(): void {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  uploadFile(
    taskId: string,
    taskFileGroupId: string,
    name: string,
    content: string,
    extension: string,
    mutation: DocumentNode,
    customPlaceHolderId?: string
  ): Observable<FetchResult<any>> {
    return this.taskFileUploadService.uploadFile(
      taskId,
      taskFileGroupId,
      name,
      content,
      extension,
      mutation,
      customPlaceHolderId
    );
  }

  async uploadFileType(
    taskId: string,
    taskFileGroupId: string,
    file: File,
    mutation: DocumentNode,
    customPlaceHolderId?: string
  ): Promise<any> {
    const re = /(?:\.([^.]+))?$/;
    return new Promise((resolve, reject) => {
      readInputFile(file).then((content: string) => {
        this.uploadFile(
          taskId,
          taskFileGroupId,
          file.name.substring(0, file.name.lastIndexOf('.')),
          content,
          re.exec(file.name)![1],
          mutation,
          customPlaceHolderId
        )
          .toPromise()
          .then((resp) => resolve(resp))
          .catch((error) => reject(error));
      });
    });
  }

  setFileGroup(
    fileId: string,
    taskFileGroupId: string,
    mutation: DocumentNode
  ): Promise<any> {
    return this.setFileGroupService
      .setFileGroup(fileId, taskFileGroupId, mutation)
      .pipe(
        map((taskFileData) => {
          return this._mapTaskFile(
            taskFileData.data.taskFile.setTaskFileGroup.output
          );
        })
      )
      .toPromise();
  }

  public setDefaultTaskFile(
    taskFileId: string,
    mutation: DocumentNode
  ): Promise<any> {
    return this.setDefaultTaskFileService
      .setDefault(taskFileId, mutation)
      .pipe(
        map((taskData) => {
          if (taskData.data.taskFile.setDefault.output) {
            return {
              output: this._mapTaskFile(
                taskData.data.taskFile.setDefault.output
              ),
              errors: null,
            };
          } else {
            return taskData.data.taskFile.setDefault;
          }
        })
      )
      .toPromise();
  }

  removeTaskFile(taskFileId: string, mutation: DocumentNode): Promise<any> {
    return this.removeTaskFileService
      .removeTaskFile(taskFileId, mutation)
      .pipe(
        map((taskData) => {
          if (taskData.data.taskFile.remove.output) {
            return {
              output: this._mapTaskFile(taskData.data.taskFile.remove.output),
              errors: null,
            };
          } else {
            return taskData.data.taskFile.remove;
          }
        })
      )
      .toPromise();
  }

  uploadInternalFile(
    taskId: string,
    name: string,
    content: string,
    extension: string,
    mutation: DocumentNode,
    customPlaceHolderId?: string
  ): Observable<FetchResult<any>> {
    return this.taskInternalFileUploadService.uploadFile(
      taskId,
      name,
      content,
      extension,
      mutation,
      customPlaceHolderId
    );
  }

  async uploadInternalFileType(
    taskId: string,
    file: File,
    mutation: DocumentNode,
    customPlaceHolderId?: string
  ): Promise<any> {
    const re = /(?:\.([^.]+))?$/;
    return new Promise((resolve, reject) => {
      readInputFile(file).then((content: string) => {
        this.uploadInternalFile(
          taskId,
          file.name.substring(0, file.name.lastIndexOf('.')),
          content,
          re.exec(file.name)![1],
          mutation,
          customPlaceHolderId
        )
          .toPromise()
          .then((resp) => resolve(resp))
          .catch((error) => reject(error));
      });
    });
  }

  removeInternalTaskFile(
    taskFileId: string,
    mutation: DocumentNode
  ): Promise<any> {
    return this.removeInternalTaskFileService
      .removeInternalTaskFile(taskFileId, mutation)
      .pipe(
        map((taskData) => {
          if (taskData.data.taskInternalFile.remove.output) {
            return {
              output: this._mapTaskFile(
                taskData.data.taskInternalFile.remove.output
              ),
              errors: null,
            };
          } else {
            return taskData.data.taskInternalFile.remove;
          }
        })
      )
      .toPromise();
  }

  public getGtNetLink(taskId: string): Observable<TaskGtNetLinkResponseInterface> {
    return this.taskGetGtNetLinkService.getGtNetLink(taskId);
  }

  public quarterYearEmailSent(taskId: string): Observable<any> {
    return this.taskQuarterYearEmailSent.quarterYearEmailSent(taskId);
  }

  onlineTaskInspectionDone(taskId: string, tokenId: string) {
    return this.onlineTaskInspectionDoneService
      .onlineTaskInspectionDone(taskId, tokenId)
      .toPromise();
  }

  private _mapPreview(taskData): any {
    const task = { ...taskData };
    task.createdAt = new Date(task.createdAt);
    if (!task.image) {
      task.image = {
        thumbnails: [
          {
            type: constants.THUMBNAIL_TYPE_SMALL,
            src: './assets/img/task-service-preview-default.svg',
          },
        ],
      };
    } else if (task.image.thumbnails.length === 0) {
      task.image = {
        thumbnails: [
          {
            type: constants.THUMBNAIL_TYPE_SMALL,
            src: './assets/img/task-service-preview-default.svg',
          },
        ],
      };
    }
    return task;
  }

  private _mapCreateOnlineInspection(
    taskData
  ): CreateOnlineInspectionInterface {
    const task = { ...taskData };
    return task;
  }

  private _mapTask(taskData): TaskServiceInterface {
    const task: any = this._mapPreview(taskData);
    task.changedAt = task.changedAt ? new Date(task.changedAt) : null;
    task.createdAt = task.createdAt ? new Date(task.createdAt) : null;
    task.updatedAt = task.updatedAt ? new Date(task.updatedAt) : null;
    task.quarterYearEmailSent = task.quarterYearEmailSent ? new Date(task.quarterYearEmailSent) : null;

    if ((task.documentationCompletedDate === undefined || task.documentationCompletedDate === null) === false) {
      task.documentationCompletedDate = new Date(task.documentationCompletedDate);
    }

    task.carTechnicalCertificateValidityDate =
      task.carTechnicalCertificateValidityDate
        ? new Date(task.carTechnicalCertificateValidityDate)
        : null;
    task.carMileageRecordDate =
      task.carMileageRecordDate
        ? new Date(task.carMileageRecordDate)
        : null;
    task.carYearOperationFrom = task.carYearOperationFrom
      ? new Date(task.carYearOperationFrom)
      : null;
    task.dateOfLoss = task.dateOfLoss ? new Date(task.dateOfLoss) : null;
    task.executionDate = task.executionDate
      ? new Date(task.executionDate)
      : null;
    if (
      task.taskFileGroups &&
      task.taskFileGroups.length > 0 &&
      task.taskFileGroups[0].messages !== undefined
    ) {
      task.canBeCalculated = task.taskFileGroups.every(
        (fileGroup) =>
          fileGroup.state === constants.TASK_FILE_GROUP_STATE_APPROVED
      );
      task.taskFileGroups = task.taskFileGroups.map((taskFileGroup) =>
        this._mapTaskFileGroup(taskFileGroup)
      );
    }
    if (task.revisionMessages) {
      task.revisionMessages = task.revisionMessages.map((taskRevisionMessage) =>
        this._mapTaskRevisionMessage(taskRevisionMessage)
      );
    }
    return task;
  }

  private _mapTaskFileGroup(fileGroupData): TaskFileGroupInterface {
    const taskFileGroup = { ...fileGroupData };
    taskFileGroup.messages = fileGroupData.messages.map((fileGroupMessage) => {
      const newFileGroupMessage = { ...fileGroupMessage };
      newFileGroupMessage.createdAt = new Date(fileGroupMessage.createdAt);
      return newFileGroupMessage;
    });
    return taskFileGroup;
  }

  private _mapTaskFile(taskFileData): TaskFileInterface {
    const taskFile = { ...taskFileData };
    taskFile.createdAt = new Date(taskFile.createdAt);
    taskFile.updatedAt = new Date(taskFile.updatedAt);
    taskFile.deletedAt = taskFile.deletedAt
      ? new Date(taskFile.deletedAt)
      : null;
    return taskFile;
  }

  private _mapTaskRevisionMessage(
    revisionMessageData
  ): RevisionMessageInterface {
    const revisionMessage = { ...revisionMessageData };
    revisionMessage.createdAt = new Date(revisionMessage.createdAt);
    return revisionMessage;
  }
}
