import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../problems/problem.service';

@Injectable()
export class TaskQuarterYearEmailSent {

  private apiUrl: string = environment.rest.apiV2Url;

  constructor(private client: HttpClient) {}

  public quarterYearEmailSent(taskId: string): Observable<any> {
    const url = `${this.apiUrl}/tasks/${taskId}/quarter-year-email-sent`;

    return this.client.post(url, {}).pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}
