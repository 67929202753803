import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../../problems/problem.service';
import { TaskSendToGtNetResponseInterface } from '../definitions/task-send-to-gtnet.interface';

@Injectable()
export class TaskSendToGtNetService {

  private apiUrl = environment.rest.apiV2Url;

  public constructor(private client: HttpClient) {}

  public taskSend(id: string): Observable<TaskSendToGtNetResponseInterface> {
    const url = `${this.apiUrl}/tasks/${id}/send-to-gtnet`;

    return this.client.post<TaskSendToGtNetResponseInterface>(url, {}).pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}
