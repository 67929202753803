import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../../problems/problem.service';
import { TaskProtectedValueDetailInterface } from '../definitions/task-protected-value-detail-interface';

@Injectable()
export class TaskProtectedValueActualDetailService {

  private apiUrl: string = environment.rest.apiV2Url;

  public constructor(private client: HttpClient) {}

  public actualDetail(taskId: string): Observable<TaskProtectedValueDetailInterface> {
    const url = `${this.apiUrl}/tasks/${taskId}/protected-value`;

    return this.client.get<TaskProtectedValueDetailInterface>(url).pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}