import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../problems/problem.service';
import { TaskGtNetLinkResponseInterface } from './definitions/task-gt-net-link-response-interface';

@Injectable()
export class TaskGetGtNetLinkService {

  private apiUrl: string = environment.rest.apiV2Url;

  constructor(private client: HttpClient) {}

  public getGtNetLink(taskId: string): Observable<TaskGtNetLinkResponseInterface> {
    const url = `${this.apiUrl}/tasks/${taskId}/gtnet-link`;

    return this.client.get<TaskGtNetLinkResponseInterface>(url).pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}
