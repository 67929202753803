import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { UpdateMeResponseInterface } from './definitions/update-me-response-interface';
import { UpdateMePatchInterface } from './definitions/update-me-patch-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

@Injectable()
export class UpdateMeService {
  constructor(private apollo: Apollo) {}

  update(
    patch: UpdateMePatchInterface,
    mutation: DocumentNode
  ): Observable<FetchResult<UpdateMeResponseInterface>> {
    return this.apollo.mutate<UpdateMeResponseInterface>({
      mutation: mutation,
      variables: {
        name: patch.name,
        notificationFileAdded: patch.notificationFileAdded,
        notificationTaskApproved: patch.notificationTaskApproved,
        notificationTaskCreated: patch.notificationTaskCreated,
        notificationApiFileAdded: patch.notificationApiFileAdded
      },
    });
  }
}
