import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskProtectedValueCreateService } from './task-protected-value-create/task-protected-value-create.service';
import { TaskProtectedValueActualDetailService } from './task-protected-value-actual-detail/task-protected-value-actual-detail.service';
import { TaskProtectedValuesListService } from './task-protected-values-list/task-protected-values-list.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    TaskProtectedValueActualDetailService,
    TaskProtectedValueCreateService,
    TaskProtectedValuesListService,
  ],
})
export class TaskProtectedValueModule {}