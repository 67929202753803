import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../../problems/problem.service';

@Injectable()
export class UpdatePartnerService {

  private apiUrl = environment.rest.apiV2Url;

  public constructor(private client: HttpClient) {}

  public partnerUpdate(id: string, updatePatch: Array<any>): Observable<any> {
    const url = `${this.apiUrl}/partners/${id}`;

    return this.client.patch<any>(url, updatePatch,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json-patch+json'
      })
    }).pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}